(function(app) {
    'use strict';

    //pa-insect
    app.directive('paInsect', function(_view, _apiService, _i18n) {
        return {
            templateUrl: '/js/components/pa/insect.html',
            restrict: 'A',
            scope: {
                insect: '=',
                index: '=',
                plantsNumber: '=',
                all: '='
            },
            link:  function (scope) {
                scope.insect.number = scope.plantsNumber;
                scope.insects = [];

                scope.remove = function (i) {
                    scope.all.splice(i, 1);
                };

                scope.selectStage = function (item) {
                    scope.insect.stages = _.filter(scope.stages, function (s) { return s.selected; });
                    if (_.size(scope.insect.stages) == 0) {
                        item.selected = true;
                        scope.insect.stages = _.filter(scope.stages, function (s) { return s.selected; });
                    }
                };

                scope.insectChanged = function () {
                    scope.stages = _.find(scope.insects, function (e) {
                        return e.id == scope.insect.insect.id;
                    }).stages;
                    _.each(scope.stages, function (s) { s.selected = true; });
                    scope.insect.stages = scope.stages; //select all stages by default

                    _apiService.insectScales(_view.getCurrentAccount(), scope.insect.insect.id).then(function(res) {
                        scope.scales = _.map(res.data, function (e) {
                            return {id: e.id, formName: _i18n.getStringFromBundleEn(e.name), items: e.items};
                        });
                        scope.insect.scale = scope.scales[0];
                    });
                };

                _apiService.getInsects(_view.getCurrentAccount()).then(function(res) {

                    _.each(res.data, function(insect) {
                        var i = insect;
                        i.formName = _i18n.getStringFromBundleLat(i.name);
                        _.each(i.stages, function(s) {
                            s.formName = _i18n.getStringFromBundleEn(s.type.name);
                        });
                        scope.insects.push(i);
                    });
                    scope.insects = _.sortBy(scope.insects, i => i.formName);

                    if (_.size(scope.insects) > 0) {
                        scope.insect.insect = scope.insects[0];
                        scope.insectChanged();
                    }
                });
            }
        };
    });
})(angular.module('app'));
